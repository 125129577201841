.home .content {
  max-inline-size: fit-content;
  margin-block-start: calc(var(--space-xl) * -5);
}

.home .content h1 {
  text-align: left;
}

.home .stack {
  --margin-start: var(--space-l, 1em);
  flex-direction: column;
  justify-content: flex-start;
  display: flex;
}

.home .hero-text-box {
  background-color: var(--bespoke-bg, var(--clr-purple-light));
  max-width: 805px;
  padding: 80px 48px;
}

.home .hero {
  inline-size: 100vw;
  flex-direction: column;
  margin-inline-start: calc(50% - 50vw);
  display: flex;
}

@media (min-width: 40rem) {
  .home .hero {
    inline-size: 100%;
    margin-inline-start: 0;
  }
}

.home .hero .hero-img {
  width: 100%;
  height: 263px;
  object-fit: cover;
  align-self: flex-end;
}

@media (min-width: 40rem) {
  .home .hero .hero-img {
    height: 40vh;
    max-height: 295px;
    min-height: 250px;
    max-width: 819px;
  }
}

@media (min-width: 55rem) {
  .home .hero .hero-img {
    height: 80vh;
    max-height: 595px;
    min-height: 350px;
    max-width: 819px;
  }
}

.home .hero .hero-text-container {
  background-color: var(--clr-green-lighter);
  max-width: 807px;
  border-radius: 4px;
  margin-bottom: -150px;
  margin-left: 16px;
  margin-right: 16px;
  padding: 26px;
  transform: translateY(-200px);
}

@media (min-width: 40rem) {
  .home .hero .hero-text-container {
    margin-bottom: -100px;
    padding: 66px 48px 45px;
    transform: translateY(-150px);
  }
}

@media (min-width: 55rem) {
  .home .hero .hero-text-container {
    margin-bottom: -218px;
    padding: 71px 54px 66px 109px;
    transform: translateY(-300px);
  }
}

.home .hero .hero-text-container h1 {
  text-align: left;
  margin-bottom: 40px;
}

.home .hero .hero-text-container .hero-text-p {
  margin-bottom: 40px;
}

.home .mission {
  text-align: center;
  align-items: center;
  margin-bottom: 68px;
}

.home .mission .mission-p {
  max-width: 858px;
}

.home .mission .mission-p:first-of-type {
  margin-top: 37px;
}

.home .what-we-do-container {
  background-color: var(--clr-purple-lighter);
  max-width: 1300px;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  margin: 0 auto 80px;
  padding: 68px 24px 48px;
  display: flex;
}

.home .what-we-do-container .what-we-do-header {
  margin-bottom: 40px;
}

.home .what-we-do-container .what-we-do-p:first-of-type {
  margin-bottom: 21px;
}

.home .what-we-do-container .what-we-do-img {
  width: 431px;
  height: auto;
}

@media (min-width: 40rem) {
  .home .what-we-do-container {
    padding: 68px 76px 85px 86px;
  }
}

@media (min-width: 55rem) {
  .home .what-we-do-container {
    flex-direction: row;
    gap: 48px;
    padding: 68px 24px 85px;
  }
}

@media (min-width: 70rem) {
  .home .what-we-do-container {
    gap: 74px;
    padding: 68px 95px 85px 112px;
  }
}

.home .partners {
  --bespoke-gap: var(--space-xl);
  text-align: center;
  align-items: center;
}

.home .partners img {
  margin-inline: auto;
}

.home .support-svg {
  height: 15px;
}

/*# sourceMappingURL=index.29e118e4.css.map */
