@use 'src/scss/abstracts/_breakpoints.scss' as *;

.home {
  .content {
    margin-block-start: calc(var(--space-xl) * -5);
    max-inline-size: fit-content;

    h1 {
      text-align: left;
    }
  }

  .stack {
    --margin-start: var(--space-l, 1em);

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
  .hero-text-box {
    background-color: var(--bespoke-bg, var(--clr-purple-light));
    padding: 80px 48px;
    max-width: 805px;
  }

  .hero {
    display: flex;
    flex-direction: column;
    inline-size: 100vw;
    margin-inline-start: calc(50% - 50vw);
    @include respond-to('small') {
      inline-size: 100%;
      margin-inline-start: 0;
    }

    .hero-img {
      width: 100%;
      // height: 116px;
      height: 263px;
      object-fit: cover;
      align-self: flex-end;

      @include respond-to('small') {
        height: 40vh;
        max-height: 295px;
        min-height: 250px;
        max-width: 819px;
      }
      @include respond-to('medium') {
        height: 80vh;
        max-height: 595px;
        min-height: 350px;
        max-width: 819px;
      }
    }
    .hero-text-container {
      background-color: var(--clr-green-lighter);
      max-width: 807px;
      padding: 26px;
      border-radius: 4px;
      margin-bottom: -150px;
      margin-left: 16px;
      margin-right: 16px;
      transform: translateY(-200px);

      @include respond-to('small') {
        padding: 66px 48px 45px 48px;
        transform: translateY(-150px);
        margin-bottom: -100px;
      }
      @include respond-to('medium') {
        padding: 71px 54px 66px 109px;
        transform: translateY(-300px);
        margin-bottom: -218px;
      }
      h1 {
        text-align: left;
        margin-bottom: 40px;
      }
      .hero-text-p {
        margin-bottom: 40px;
      }
    }
  }

  .mission {
    align-items: center;
    text-align: center;
    margin-bottom: 68px;

    .mission-p {
      max-width: 858px;

      &:first-of-type {
        margin-top: 37px;
      }
    }
  }

  .what-we-do-container {
    background-color: var(--clr-purple-lighter);

    max-width: 1300px;
    margin: 0 auto;
    margin-bottom: 80px;
    display: flex;
    flex-direction: column;
    padding: 68px 24px 48px 24px;
    align-items: center;
    gap: 32px;

    .what-we-do-header {
      margin-bottom: 40px;
    }
    .what-we-do-p {
      &:first-of-type {
        margin-bottom: 21px;
      }
    }
    .what-we-do-img {
      width: 431px;
      height: auto;
    }

    @include respond-to('small') {
      padding: 68px 76px 85px 86px;
    }
    @include respond-to('medium') {
      flex-direction: row;
      gap: 48px;
      padding: 68px 24px 85px 24px;
    }
    @include respond-to('large') {
      gap: 74px;
      padding: 68px 95px 85px 112px;
    }
  }

  .partners {
    --bespoke-gap: var(--space-xl);

    align-items: center;
    text-align: center;

    img {
      margin-inline-start: auto;
      margin-inline-end: auto;
    }
  }
  .support-svg {
    height: 15px;
  }
}
